@light: #FFF;
@body-bg: #F4F5FA;
@blue-1: #083B6E;
@blue-2: rgba(72, 52, 212, 0.8);
@item-selected-color: @light;
@dark: #130F40;
@gray: #858C97;
@light: #FBFBFB;
@success: #00B74A;
@danger: #F93154;
@warning: #FFA900;



#root {
    background-color: @body-bg;
}

.header-light {
    background-color: @menu-light-bg;
}

.header-dark {
    background-color: @menu-dark-bg;
}

.site-layout-background {
    background-color: @body-bg;
}

.ant-layout-header {
    padding: 0px;
}

.ant-modal-content {
    border-radius: 8px;
}
.ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.header {
    display: flex;
    height: auto;
    line-height: normal;
    justify-content: center;
    width: 100%;
    padding: 12px 0px;
}

.page-title {
    flex: 1;
    align-self: center;
}

.site-menu {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 290px;
    background-color: transparent;
    border: none;
}

.site-menu.sidebar-collapsed {
    margin-right: 92px;
}

@media (max-width: 500px) {
    .site-menu.sidebar-collapsed {
        margin-right: 10px;
    }
}

@media (max-width: 36s0px) {
    .site-menu.sidebar-collapsed .ant-menu-item {
        padding: 4px 2px !important;
    }
}

.site-menu .ant-menu-item, .site-menu .ant-menu-submenu {
    padding: 4px 2px !important;
}

.site-menu .ant-menu-item-selected {
    background-color: @light;
    border-radius: 8px;
}


/**
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #083B6E;
}
*/


.site-menu > li > * {
    color: @blue-1;
}
.site-menu > li:hover::after,
.site-menu > li::after, .site-menu > li > *:hover  {
    border-bottom: none !important;
    color: @blue-2;
}

.sidebar-menu {
    padding: 0px 20px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    background-color: transparent;
}

.sidebar-menu > * {
    transition: all 0.5s !important;
}

.header .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.sidebar-menu__bottom {
    padding: 0px 48px !important;
    position: absolute;
    bottom: 20px;
}

.sidebar-menu__bottom * {
    color: @gray;
    letter-spacing: 0.002em;
    line-height: 200%;
    font-size: 12px;
}

.sidebar-menu .ant-menu-item.ant-menu-item-selected {
    display: flex;
    align-items: center;
    background-color: @item-selected-color !important;
    font-weight: 500;
    border-radius: 0.5rem;
}

.has-collapsed .ant-menu-item.ant-menu-item-selected {
    border-radius: 20%;
}


.ant-menu-vertical 
.ant-menu-item:not(:last-child), 
.ant-menu-vertical-left .ant-menu-item:not(:last-child), 
.ant-menu-vertical-right .ant-menu-item:not(:last-child), 
.ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 18px;
    margin-top: 16px;
}

.ant-menu-item-selected * {
    color: @blue-1 !important;
    fill: @blue-1 !important;
}

.sidebar-menu__closer {
    padding: 0rem 1.8rem !important;
}
.sidebar-menu__collapsed {
    padding: 0.2rem 1.8rem !important;
}
.sidebar-menu__closer span {
    color: @dark;
    transition: all 0.1s;
}

.sidebar-menu__closer span:hover {
    color: @gray
}

.sidebar-menu__closer-icon {
    display: inline-flex;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: @menu-light-bg;
    justify-content: center;
    align-items: center;
}

.flex-right {
    justify-content: flex-end;
}
.flex-center {
    justify-content: flex-end;
}

.flex {
    display: flex;
}

.size-20 {
    font-size: 20px !important;
}
.text-dark {
    color: @dark !important;
}
.text-gray {
    color: @gray !important;
}
.text-blue {
    color: @blue-1;
}
.mx-1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.ml-1 {
    margin-left: 1rem;
}

.py {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
}

.counter-box__menu {
    font-weight: 600;
    display: flex;
}

.counter-box__menu > * {
    color: @gray;
}

.counter-box .ant-space {
    display: flex;
    width: 100%;
}

.counter-box__title {
    font-size: 10px;
    color: #130F40;
    line-height: 16px;
}

.word-break {
    display: inline-block;
    word-wrap: break-word;
    max-width: 60px;
}

.counter-box__label {
    font-weight: 600;
    font-size: 18px;
    display: inline-block;
}

.counter-box__body_title {
    color: #6FCF97;
    font-size: 12px;
    font-weight: 500;
}

.counter-box__body_label {
    color: #89879F;
    font-size: 12px;
    font-weight: 500;
}

.counter-box__icon {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    justify-content: center !important;
    align-items: center;
}

.text-default {
    color: rgba(19, 15, 64, 1) !important;
    font-weight: 600;
}

.text-warning {
    color: rgba(255, 180, 10, 1) !important;
    font-weight: 600;
}

.text-success {
    color: rgba(24, 171, 79, 1) !important;
}

.text-danger {
    color: rgba(224, 32, 32, 1) !important;
}


.bg-default {
    background: rgba(19, 15, 64, 0.05) !important;
}

.bg-warning {
    background: rgba(255, 180, 10, 0.05) !important;
}

.bg-success {
    background: rgba(24, 171, 79, 0.05) !important;
}

.bg-danger {
    background: rgba(224, 32, 32, 0.05) !important;
}

.bg-primary-light {
    background-color: rgba(154, 159, 191, 0.1) !important;
}

.container {
    width: 100% !important;
    max-width: auto;
}

.header-page_title .ant-space-item {
    display: flex;
    justify-content: center;
    align-items: center;
}


.nav-tab-container {
    background-color: transparent;
    padding: 4px 8px;
    border-radius: 8px;
    display: inline-block;
}

.nav-tab-container > li > * {
    color: rgba(154, 159, 191, 0.75);
    font-size: 14px !important;
}

.nav-tab-container > li:hover::after,
.nav-tab-container > li::after, .site-menu > li > *:hover  {
    border-bottom: none !important;
    color: @gray;
    font-weight: 600 !important;
}


.nav-tab-container > .ant-menu-item-selected {
    background: rgba(8, 59, 110, 0);
    border-radius: 0px;
    border-bottom: 2px solid @gray;
}

.nav-tab-container > .ant-menu-item-selected > * {
    color: @blue-1 !important;
}

.nav-tab-container.ant-menu-horizontal {
    line-height: 28px;
    border: 0;
    box-shadow: none;
}

.status-before-content::before {
    display: inline-block;
    width: 14px;
    height: 14px;
    content: '';
    position: relative;
    top: 4px;
    left: -10px;
    background-color: @gray;
}
.status-before-content.shape-circle::before {
    border-radius: 50%;
}
.table .ant-table-thead > tr > th {
    background: transparent;
    font-weight: bold;
    font-size: 12px;
}
.table .ant-table-tbody > tr > td {
    font-size: 12px;
}
.table .ant-table-tbody > tr:nth-child(2n + 2), .table .ant-table-tbody > tr:hover {
    background-color: rgba(244, 245, 250, 1);
    cursor: pointer;
}
.table .ant-table-pagination.ant-pagination {
    margin: 32px 0;
}
.status-before-content.success::before {
    background-color: @success;
}

.status-before-content.canceled::before, .status-before-content.failed::before {
    background: @danger;
}

.status-before-content.pending::before {
    background: @warning;
}
.text-pending {
    color: @warning;
}
.text-success {
    color: @success;
}
.text-declined {
    color: @danger;
}

.search-input.ant-input-affix-wrapper, .filter-date, .select-input.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(154, 159, 191, 0.1);
    border-radius: 8px;
    border: none;
}

.search-input.ant-input-affix-wrapper input, .filter-date .ant-picker {
    background-color: transparent;
    border: none;
}

.stroke path {
    stroke:rgba(154, 159, 191, 0.75);
}

.fill path {
    fill:rgba(154, 159, 191, 0.75);
}

.select-input {
    width: 100%;
}
.select-input .ant-select-dropdown {
    width: 100%;
}

.filter-date {
    width: 100% !important;
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.filter-date .ant-picker {
    width: 100%;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: @dark;
    border-color: @dark;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background: rgba(61, 60, 60, 0.1);
    border-color: rgba(0, 0, 0, 0.03);
}

.button-input {
    border-radius: 8px;
    margin-top: -2px;
}

.w-0 {
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}

.ant-btn-primary {
    height: 48px;
    border-radius: 8px;
}

.h-label {
    color: @gray;
    display: block;
}
.h-value {
    display: block;
    color: @blue-1;
    font-weight: 600;
    margin-bottom: 8px;
}


.info__container {
   background-color: #fff; 
   padding: 1.5rem 1rem;
   border-radius: 8px;
}

.info__title {
    font-size: 12px;
    font-weight: 400;
}

.info__description {
    font-size: 16px;
    font-weight: 500;
    word-wrap: break-word;
}

.hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.round {
    border-radius: 32px !important;
}

.btn {
    display: inline-flex;
    align-items: center;
    padding: 12px 14px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: @light;
}
.btn-success {
    background-color: @light;
    color: @success;
    transition: all 0.5s ease-in;
}
.btn-success:hover {
    background-color: fade(@success, 1%);
}
.btn-warning {
    background-color: @light;
    color: @warning;
    transition: all 0.5s ease-in;
}
.btn-warning:hover {
    background-color: fade(@warning, 1%);
}
.btn-danger {
    background-color: @light;
    color: @danger;
    transition: all 0.5s ease-in;
}
.btn-danger:hover {
    background-color: fade(@danger, 1%);
}
.btn-primary {
    background-color: @light;
    color: @dark;
    transition: all 0.5s ease-in;
}
.btn-primary:hover {
    background-color: fade(@dark, 2%);
}

.h1 {
    font-weight: 500;
    font-size: 20px;
    margin: 8px 0px;
    color:rgba(0, 0, 0, 0.85)
}
.h4 {
    font-weight: 500;
    font-size: 12px;
    margin: 8px 0px;
    color:rgba(0, 0, 0, 0.65)
}
.ant-picker, .ant-picker-input > input {
    background-color: @light;
    border: none;
}
.ant-picker {
    border-radius: 8px;
}
.shadow-1 {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
    border: 2px solid transparent;
}




